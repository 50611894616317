<script>
import { Bar } from "vue-chartjs";

export default {
  extends: Bar,
  props: ['content'],
  data() {
    return {
      labels: [],
      data: [],
      total: 0,
      label: '',
      bgColor: '',
    };
  },
  watch: {
    content: {
        handler (data) {
            // this.extends.destroy();
            this.renderCharts(data);
        },
        deep: true,
      },
    },
methods: {
    renderCharts(content){
      this.labels = content.labels;
      this.data = content.data;
      this.total = content.total;
      this.label = content.label; 
      this.bgColor = content.bgColor || "#43a047"; 
      this.renderChart(
      {
        labels: this.labels,
        datasets: [
          {
            label: this.label,
            backgroundColor: this.bgColor,
            data: this.data
          }
        ]
      },
      { responsive: true, maintainAspectRatio: false }
    );
    }
}
};
</script>
